import { useContext, useState } from "react"
import { publicIpv4 } from "public-ip"
import axios from "axios"
import { messageToToasts, API_URL, isEmpty, validateEmail } from "../utils"
import { UserContext } from "../context/UserContext"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { errorMessage } from "../errors"

const LoginForm = ({ userType }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const [redEmail, setRedEmail] = useState(false)
  const [redPassword, setRedPassword] = useState(false)

  const { login } = useContext(UserContext)
  const { t } = useTranslation()

  const signIn = async event => {
    event.preventDefault()

    if (isEmpty(email)) {
      setRedEmail(true)
      return toast.error(errorMessage("auth:::009"))
    }

    if (!validateEmail(email)) {
      setRedEmail(true)
      return toast.error(errorMessage("auth:::011"))
    }

    if (password === "") {
      setRedPassword(true)
      return toast.error(errorMessage("auth:::010"))
    }

    setSubmitting(true)

    try {
      const clietnIP = ""

      const data = {
        email: email.trim().toLowerCase(),
        password,
        userType,
        clietnIP,
      }

      const resp = await axios.post(`${API_URL}/auth/signIn`, data)
      const { status, message, jwt } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        setRedEmail(true)
        setRedPassword(true)

        return messageToToasts(message)
      }
      login(jwt)
    } catch (error) {
      toast.error(errorMessage("sw:::000"))
    }

    setSubmitting(false)
  }

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-4">
          <form className="box">
            <div className="field">
              <label className="label has-text-left">{t("auth.email")}</label>
              <div className="control">
                <input
                  className={`input ${redEmail ? "is-danger" : ""}`}
                  type="email"
                  value={email}
                  placeholder="somemail@gmail.com"
                  onChange={e => setEmail(e.target.value)}
                  onFocus={e => setRedEmail(false)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label has-text-left">
                {t("auth.password")}
              </label>
              <div className="control">
                <input
                  className={`input ${redPassword ? "is-danger" : ""}`}
                  type="password"
                  value={password}
                  placeholder="********"
                  onChange={e => setPassword(e.target.value)}
                  onFocus={e => setRedPassword(false)}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <div
                  className={`button ${submitting ? "is-loading" : ""} is-dark`}
                  type="submit"
                  onClick={e => signIn(e)}
                >
                  {t("auth.logIn")}
                </div>
              </div>
            </div>
          </form>
          <hr />
        </div>
      </div>
    </>
  )
}

export default LoginForm
