import { createContext, useState, useEffect } from "react"
import axios from "axios"
import dayjs from "dayjs"

import * as jose from 'jose'


const userFromToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token
    const { userID, email, userType } = jose.decodeJwt(token)
    return { userID, email, userType }
  } else {
    return {}
  }
}

const tokenFromStorage = localStorage.getItem("token") || ""
const userFromStorage = userFromToken(tokenFromStorage)

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(tokenFromStorage)
  const [user, setUser] = useState(userFromStorage)

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = token
  }, [token])

  const login = newToken => {
    localStorage.setItem("token", newToken)
    setUser(userFromToken(newToken))
    setToken(newToken)
  }

  const logout = () => {
    localStorage.removeItem("token")
    setUser({})
    setToken("")
  }

  useEffect(() => {
    if (token !== "") {
      const { exp } = jose.decodeJwt(token)

      const checkTime = Math.floor(
        new Date(dayjs().add(3, "h")).getTime() / 1000
      )

      if (exp < checkTime) {
        logout()
      }
    }
    // eslint-disable-next-line
  }, [])

  const value = {
    token,
    user,
    login,
    logout,
    isSigned: token.length,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
